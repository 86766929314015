import { createAction, props } from '@ngrx/store';

export const LoadPracticesAction = createAction(
  'LOAD_PRACTICES'
);

export const LoadPracticesSuccessAction = createAction(
  'LOAD_PRACTICES_SUCCESS',
  props<{ payload: []}>()
);

export const LoadPracticesFailAction = createAction(
  'LOAD_PRACTICES_FAIL',
  props<{payload:{}}>()
);

export const ExtendTrialAction = createAction(
  'EXTEND_TRIAL',
  props<{payload:{}}>()
);

export const ExtendTrialActionSuccess = createAction(
  'EXTEND_TRIAL_SUCCESS',
  props<{payload:{}}>()
);

export const UpdateUrlAction = createAction(
  'UPDATE_URL',
  props<{payload:{}}>()
);

export const UpdateUrlActionSuccess = createAction(
  'UPDATE_URL_SUCCESS',
  props<{payload:{}}>()
);

export const UpdatePromoAction = createAction(
  'UPDATE_PROMO',
  props<{payload:{}}>()
);

export const UpdatePromoActionSuccess = createAction(
  'UPDATE_PROMO_SUCCESS',
  props<{payload:{}}>()
);

export const UpdateStatusAction = createAction(
  'UPDATE_STATUS',
  props<{payload:{}}>()
);

export const UpdateStatusActionSuccess = createAction(
  'UPDATE_STATUS_SUCCESS',
  props<{payload:{}}>()
);