import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
//Modules
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import { StoreModule } from '@ngrx/store';
import { loginReducer } from './auth/login/store/login.reducers';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from './auth/login/store/login.effects';
import { SidebarModule } from './pages/sidebar/sidebar.module';
import { JwtInterceptorProvider } from './_helpers/jwt.interceptor';
import { HeaderModule } from './pages/header/header.module';
import { AgGridModule } from 'ag-grid-angular';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AuthGuard } from './_guards';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CustomizerSettingsComponent } from './components/customizer-settings/customizer-settings.component';
import { MatDividerModule } from '@angular/material/divider';
import { CommonStore } from './shared/store/common.store';
import { PracticeStore } from './shared/store/practices.store';
import { DatePipe } from '@angular/common';
import { UserStore } from './shared/store/users.store';
import { CompanyStore } from './shared/store/clients.store';
import { InvoiceStore } from './shared/store/invoices.store';
import { PromoStore } from './shared/store/promos.store';

@NgModule({
  declarations: [
    AppComponent,
    CustomizerSettingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgScrollbarModule,
    MatSidenavModule,MatToolbarModule,MatSnackBarModule,MatButtonModule,MatIconModule,MatNativeDateModule,MatMomentDateModule,
    MatDividerModule,
    StoreModule.forRoot({login:loginReducer}),
    EffectsModule.forRoot(LoginEffects),
    SidebarModule, HeaderModule,
    AgGridModule
  ],
  providers: [JwtInterceptorProvider,
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    AuthGuard,CommonStore,PracticeStore,UserStore,CompanyStore,InvoiceStore,PromoStore,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
