import { createAction, props } from '@ngrx/store';

export const LoadUsersAction = createAction(
  'LOAD_USERS'
);

export const LoadUsersSuccessAction = createAction(
  'LOAD_USERS_SUCCESS',
  props<{ payload: []}>()
);

export const LoadUsersFailAction = createAction(
  'LOAD_USERS_FAIL',
  props<{payload:{}}>()
);

export const UpdatePasswordAction = createAction(
  'UPDATE_PASSWORD',
  props<{payload:{}}>()
);

export const VerifyUserAction = createAction(
  'VERIFY_USER',
  props<{payload:{}}>()
);


export const VerifyUserActionSuccess = createAction(
  'VERIFY_USER_SUCCESS',
  props<{payload:{}}>()
);

export const MobileNumberAction = createAction(
  'MOBILE_NUMBER',
  props<{payload:{}}>()
);


export const MobileNumberActionSuccess = createAction(
  'MOBILE_NUMBER_SUCCESS',
  props<{payload:{}}>()
);