import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store"
import { LoadInvoicesAction, LoadInvoicesSuccessAction } from "./invoices.actions";
import { PracticesState } from "../../practices/store/practices.reducers";
// import { PracticesState } from "../../practices/store/practices.reducers";
export interface InvoicesState {
    invLoader: boolean;
    invData: any;
    invCount: number
  };
  
  const initialState: InvoicesState = {
    invLoader: false,
    invData: [],
    invCount: 0
  };
  export const invoiceFeatureKey = 'invoices';

const _invoiceReducer=createReducer(initialState,
    on(LoadInvoicesAction,(state,action)=>{
        //const user = {"username":action.username,"password":action.password};
        return{
            ...state,
            invLoader: true
        }
    }),
    on(LoadInvoicesSuccessAction,(state,action)=>{
        const data = action.payload;
        return{
            ...state,
            invLoader: false,
            invData: data,
            invCount: data.length
        }
    })
)

export const selectInvoices = (state: InvoicesState) => state;
const selectPracticesState = createFeatureSelector<PracticesState>('practices');

export const selectedPractices = createSelector(
  selectPracticesState,
  (state: PracticesState) => state.orgData
);
export const getInvoicesState = createFeatureSelector<InvoicesState>(invoiceFeatureKey);
export const getAllInvData = createSelector(getInvoicesState, (s) =>  {
    return s.invData;
})
export const getAllInvoices = createSelector(
    getAllInvData,
    selectedPractices,
    (invoices: any, practices: any) => {
        return invoices.map((invoice: any) => {
            const organization = practices.find((org: any) => org._id == invoice.organizationId);
            const organizationname = organization ? organization.organizationname : '';
            return {
              ...invoice,
              organizationname
            };
          });
    }
  );
export function invoiceReducer(state: any,action: any){
    return _invoiceReducer(state,action)
}

