import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store"
import { LoadPromosAction, LoadPromosSuccessAction } from "./promos.actions";
import moment  from "moment";
export interface PromosState {
    promoLoader: boolean;
    promoData: any;
    promoCount: number
  };
  
  const initialState: PromosState = {
    promoLoader: false,
    promoData: [],
    promoCount: 0
  };
export const promosFeatureKey = 'promos';

const _promoReducer=createReducer(initialState,
    on(LoadPromosAction,(state,action)=>{
        //const user = {"username":action.username,"password":action.password};
        return{
            ...state,
            promoLoader: true
        }
    }),
    on(LoadPromosSuccessAction,(state,action)=>{
        const data = action.payload;
        return{
            ...state,
            promoLoader: false,
            promoData: data,
            promoCount: data.length
        }
    })
)

export const selectPromos = (state: PromosState) => state;

export const getActivePromos = createSelector(
    selectPromos,
    (data: any) => {
        if(data){
            let filteredData = data.promos.promoData.filter((item: any)=> {
                let today = moment();
                if(moment(item.endDate).isAfter(today)){
                    return item;
                }
                //if(item.)
            })
            return filteredData;
        }     
    }
);

export function promoReducer(state: any,action: any){
    return _promoReducer(state,action)
}

export const getPromosState = createFeatureSelector<PromosState>(promosFeatureKey);
export const getAllPromos = createSelector(getPromosState, (s) =>  {
    return s.promoData;
})
