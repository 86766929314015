import { createAction, props } from '@ngrx/store';

export const LoadInvoicesAction = createAction(
  'LOAD_INVOICES'
);

export const LoadInvoicesSuccessAction = createAction(
  'LOAD_INVOICES_SUCCESS',
  props<{ payload: []}>()
);

export const LoadInvoicesFailAction = createAction(
  'LOAD_INVOICES_FAIL',
  props<{payload:{}}>()
);