import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import {
    LoadPromosAction,
    LoadPromosFailAction,
    LoadPromosSuccessAction,
  } from './promos.actions';
  import { SharedService } from '../../../services/shared.service';
import { PromoService } from '../../../services/promos.service';
  
  @Injectable()
  export class PromosEffects {
  
    loadPromos$ = createEffect(() =>
      this.actions$.pipe(
        // Filters by Action Creator 'login'
        ofType(LoadPromosAction),
        exhaustMap(() =>
          this.promoService.getPromos().pipe(
            map((data: any) => {
                return LoadPromosSuccessAction({payload: data});
            }),
            catchError((error) => of(LoadPromosFailAction(error)))
          )
        )
      )
    );
    constructor(
      private actions$: Actions,
      private promoService: PromoService,
      private sharedService: SharedService
    ) {}
  }
  