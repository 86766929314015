import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import {
    LoadInvoicesAction,
    LoadInvoicesFailAction,
    LoadInvoicesSuccessAction,
  } from './invoices.actions';
  import { InvoiceService } from '../../../services/invoices.service';
  import { SharedService } from '../../../services/shared.service';
  
  @Injectable()
  export class InvoicesEffects {
  
    loadInvoices$ = createEffect(() =>
      this.actions$.pipe(
        // Filters by Action Creator 'login'
        ofType(LoadInvoicesAction),
        exhaustMap(() =>
          this.invoiceService.getInvoices().pipe(
            map((data: any) => {
                return LoadInvoicesSuccessAction({payload: data});
            }),
            catchError((error) => of(LoadInvoicesFailAction(error)))
          )
        )
      )
    );
    constructor(
      private actions$: Actions,
      private invoiceService: InvoiceService,
      private sharedService: SharedService
    ) {}
  }
  