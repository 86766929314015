import { ChangeDetectionStrategy, Component, OnInit, computed, effect, inject, signal } from '@angular/core';
import { getAllPractices, selectedCompanies, selectedDeletedCompanies, selectedInvoices, selectedPromos, selectedUsers } from '../practices/store/practices.reducers';
import { LoadPracticesAction } from '../practices/store/practices.actions';
import { getAllCompanies, getAllDelCompanies } from '../companies/store/companies.reducers';
import { LoadCompaniesAction, LoadDeletedCompaniesAction } from '../companies/store/companies.actions';
import { Store } from '@ngrx/store';
import { getAllUsers } from '../users/store/users.reducers';
import { LoadUsersAction } from '../users/store/users.actions';
import { getAllPromos } from '../promos/store/promos.reducers';
import { LoadPromosAction } from '../promos/store/promos.actions';
import { ToggleService } from '../header/toggle.service';
import { CustomizerSettingsService } from '../../components/customizer-settings/customizer-settings.service';
import { getAllInvData } from '../invoices/store/invoices.reducers';
import { LoadInvoicesAction } from '../invoices/store/invoices.actions';
import { CommonStore } from '../../shared/store/common.store';
import { PracticeStore } from '../../shared/store/practices.store';
import { UserStore } from '../../shared/store/users.store';
import { CompanyStore } from '../../shared/store/clients.store';
import { InvoiceStore } from '../../shared/store/invoices.store';
import { PromoStore } from '../../shared/store/promos.store';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {
  panelOpenState = false;
  isToggled = signal<boolean>(false);
  getAllPractices: any;
  public store = inject(Store);
  //public practices = this.store.selectSignal(getAllPractices);
  public companies = this.store.selectSignal(getAllCompanies);
  public delCompanies = this.store.selectSignal(getAllDelCompanies);
  public promos = this.store.selectSignal(getAllPromos);
  public users = this.store.selectSignal(getAllUsers);
  public invoices = this.store.selectSignal(getAllInvData);
  
  public selectedUsers = this.store.selectSignal(selectedUsers);
  public selectedCompanies = this.store.selectSignal(selectedCompanies);
  public selectedInvoices = this.store.selectSignal(selectedInvoices);
  public selectedPromos = this.store.selectSignal(selectedPromos);
  public selectedDeletedCompanies = this.store.selectSignal(selectedDeletedCompanies);
  commonStore = inject(CommonStore);
  practiceStore = inject(PracticeStore);
  userStore = inject(UserStore);
  companyStore = inject(CompanyStore);
  invoiceStore = inject(InvoiceStore);
  promoStore = inject(PromoStore);
  sideBarNavItems = computed(() => {
    let setSideBarNavItems: any[] = [];
    if(localStorage.getItem("isSub") == "true"){
      setSideBarNavItems = [
        {
          path: "/practices",
          title: "Practices",
          icon: "flaticon-contract",
          class: "",
          extralink: false,
          submenu: [],
          count: this.practiceStore.orgData().length,
          id: 2,
          
        },
        {
          path: "/users",
          title: "Users",
          icon: "flaticon-people",
          class: "flaticon-people",
          extralink: false,
          submenu: [],
          count: this.userStore.userData().length,
          id: 3,
          countClass :"three"
        },
        {
          path: "/companies",
          title: "Clients",
          icon: "flaticon-diamond-1",
          class: "",
          extralink: false,
          submenu: [],
          count: this.companyStore.compData().length,
          id: 4,
  
        },
        {
          path: "/deleted-companies",
          title: "Deleted Clients",
          icon: "flaticon-diamond-1",
          class: "",
          extralink: false,
          submenu: [],
          count: this.companyStore.delCompData().length,
          id: 5,
          countClass :"two"
  
        },
        {
          path: "/invoices",
          title: "Invoices",
          icon: "flaticon-list",
          class: "",
          extralink: false,
          submenu: [],
          count: this.invoiceStore.invData().length,
          id: 6,
          countClass :"three"
  
        },
        {
          path: "/promos",
          title: "Promos",
          icon: "flaticon-award",
          class: "",
          extralink: false,
          submenu: [],
          count: this.promoStore.promoData().length,
          id: 7,
  
        }
      ];
    }else{
      setSideBarNavItems = [
        {
          path: "/dashboard",
          title: "Dashboard",
          icon: "flaticon-menu-1",
          class: "",
          extralink: false,
          submenu: [],
          id: 1,
        },
        {
          path: "/practices",
          title: "Practices",
          icon: "flaticon-contract",
          class: "",
          extralink: false,
          submenu: [],
          count: this.practiceStore.orgData().length,
          id: 2,
          
        },
        {
          path: "/users",
          title: "Users",
          icon: "flaticon-people",
          class: "flaticon-people",
          extralink: false,
          submenu: [],
          count: this.userStore.userData().length,
          id: 3,
          countClass :"three"
        },
        {
          path: "/companies",
          title: "Clients",
          icon: "flaticon-diamond-1",
          class: "",
          extralink: false,
          submenu: [],
          count: this.companyStore.compData().length,
          id: 4,
  
        },
        {
          path: "/deleted-companies",
          title: "Deleted Clients",
          icon: "flaticon-diamond-1",
          class: "",
          extralink: false,
          submenu: [],
          count: this.companyStore.delCompData().length,
          id: 5,
          countClass :"two"
  
        },
        {
          path: "/invoices",
          title: "Invoices",
          icon: "flaticon-list",
          class: "",
          extralink: false,
          submenu: [],
          count: this.invoiceStore.invData().length,
          id: 6,
          countClass :"three"
  
        },
        {
          path: "/promos",
          title: "Promos",
          icon: "flaticon-award",
          class: "",
          extralink: false,
          submenu: [],
          count: this.promoStore.promoData().length,
          id: 7,
  
        }
      ];
    }
    
    return setSideBarNavItems;
  });


  constructor(
    private toggleService: ToggleService,
    public themeService: CustomizerSettingsService
  ){
    this.toggleService.isToggled$.subscribe(isToggled => {
      console.log('this.isToggled' , isToggled);
      this.isToggled.set(isToggled)
    });
    
    effect(() => {
      // const selectedUsers = this.selectedUsers();
      // const selectedCompanies = this.selectedCompanies();
      // const selectedInvoices = this.selectedInvoices();
      // const selectedPromos = this.selectedPromos();
      // const selectedDeletedCompanies = this.selectedDeletedCompanies();
      //console.log(this.commonStore.userData());
      console.log("Called Sidebar effect")
      
      this.commonStore.setDashboardLogic({
          getAllPractices: this.practiceStore.orgData(),
        })
    }, { allowSignalWrites: true })
  }
  ngOnInit(){
    this.practiceStore.loadPractices();
    this.userStore.loadUsers();
    this.companyStore.loadCompanies();
    this.companyStore.loadDelCompanies();
    this.invoiceStore.loadInvoives();
    this.promoStore.loadPromos();
    //this.commonStore.setDashboardLogic({});
    //this.store.dispatch(LoadPracticesAction());
    // this.store.dispatch(LoadCompaniesAction());
    // this.store.dispatch(LoadUsersAction());
    // this.store.dispatch(LoadPromosAction());
    // this.store.dispatch(LoadInvoicesAction());
    // this.store.dispatch(LoadDeletedCompaniesAction());


  }
  toggle() {
    this.toggleService.toggle();
}
toggleSidebarTheme() {
  this.themeService.toggleSidebarTheme();
}

toggleHideSidebarTheme() {
  this.themeService.toggleHideSidebarTheme();
}
}