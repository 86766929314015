import { createAction, props } from '@ngrx/store';

export const LoadPromosAction = createAction(
  'LOAD_PROMOS'
);

export const LoadPromosSuccessAction = createAction(
  'LOAD_PROMOS_SUCCESS',
  props<{ payload: []}>()
);

export const LoadPromosFailAction = createAction(
  'LOAD_PROMOS_FAIL',
  props<{payload:{}}>()
);