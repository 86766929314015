import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import {
    LoadCompaniesAction,
    LoadCompaniesFailAction,
    LoadCompaniesSuccessAction,
    LoadDeletedCompaniesAction,
    LoadDeletedCompaniesFailAction,
    LoadDeletedCompaniesSuccessAction,
    SyncTransAction,
    UpdateSyncDateAction,
    UpdateSyncDateActionSuccess,
  } from './companies.actions';
  import { CompanyService } from '../../../services/companies.service';
  import { SharedService } from '../../../services/shared.service';
  
  @Injectable()
  export class CompaniesEffects {
  
    loadCompanies$ = createEffect(() =>
      this.actions$.pipe(
        // Filters by Action Creator 'login'
        ofType(LoadCompaniesAction),
        exhaustMap(() =>
          this.companyService.getCompanies().pipe(
            map((data: any) => {
                return LoadCompaniesSuccessAction({payload: data});
            }),
            catchError((error) => of(LoadCompaniesFailAction(error)))
          )
        )
      )
    );

    loadDelCompanies$ = createEffect(() =>
      this.actions$.pipe(
        // Filters by Action Creator 'login'
        ofType(LoadDeletedCompaniesAction),
        exhaustMap(() =>
          this.companyService.getDeletedCompanies().pipe(
            map((data: any) => {
                return LoadDeletedCompaniesSuccessAction({payload: data});
            }),
            catchError((error) => of(LoadDeletedCompaniesFailAction(error)))
          )
        )
      )
    );

    syncTrans$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType(SyncTransAction),
      exhaustMap((action) =>
        this.companyService.syncTrans(action).pipe(
          map((data: any) => {
            this.sharedService.messageService('Sync Started!', 5000, 'center', 'top')
              return data;
          }),
          catchError((error) => of(LoadCompaniesFailAction(error)))
        )
      )
    ),{dispatch: false}
  );

  updateSyncDate$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType(UpdateSyncDateAction),
      exhaustMap((action) => 
        this.companyService.updateSyncDate(action).pipe(
          map((data: any) => {
            if(data.success){
              this.sharedService.messageService('Updated Successfully!', 5000, 'center', 'top')
            }else{
              this.sharedService.messageService('Update Failed!', 5000, 'center', 'top')
            }

            return UpdateSyncDateActionSuccess({payload: data});
            //return data;
          }),
          catchError((error) => of(LoadCompaniesFailAction(error)))
        )
      )
    )
  );

    constructor(
      private actions$: Actions,
      private companyService: CompanyService,
      private sharedService: SharedService
    ) {}
  }
  