import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  LoginAction,
  LoginSuccessAction,
  LoginFailAction,
} from './login.actions';
import { AuthService } from '../../../services/auth.service';
import { RouteConst } from '../../../shared/routes.constants';
import { SharedService } from '../../../services/shared.service';

@Injectable()
export class LoginEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType(LoginAction),
      exhaustMap((action) =>
        this.authService.login(action.username, action.password).pipe(
          map((user) => {return LoginSuccessAction(user)}),
          catchError((error) => of(LoginFailAction(error)))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginSuccessAction),
      tap((payload: any) => {
        if (payload) {
        let user = payload;
        if (user.isAdmin) {
            if (user && user.token) {
                localStorage.setItem('currentUser', JSON.stringify(user));
                localStorage.setItem('userId', user._id);
                localStorage.setItem('token', user.token);
                localStorage.setItem('username', user.firstName + ' ' + user.lastName);
                localStorage.setItem('email', user.email);
                localStorage.setItem('newModuleFlag', user.newModuleFlag);
                localStorage.setItem('twofactoauthentication', user.twofactoauthentication);
                localStorage.setItem('isAdmin', user.isAdmin ? 'true' : 'false');
                localStorage.setItem('isSub', user.isSub ? 'true' : 'false');
                //new commonActions.LoadUserAction(user);
                if(!user.isSub)
                  this.router.navigate([RouteConst.dashboard]);
                else
                  this.router.navigate([RouteConst.practice]);
            }
        } else {
            this.sharedService.messageService('Only Admin can Login!', 5000, 'center', 'top')
        }
         }
        
        // return true;
      })
    ),
    {dispatch:false}
  );

  loginFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginFailAction),
      tap((resp: any) => {
        if (resp.error == 'email') {
            this.sharedService.messageService('Email not registered', 5000, 'center', 'top')
        } else if (resp.error == 'password') {
            this.sharedService.messageService('Invalid Password', 5000, 'center', 'top')
        }
      })
    ),
    {dispatch:false}
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private sharedService: SharedService
  ) {}
}
