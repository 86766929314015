import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store"
import { LoadPracticesAction, LoadPracticesSuccessAction, ExtendTrialActionSuccess, UpdateUrlActionSuccess, UpdatePromoActionSuccess, UpdateStatusActionSuccess } from "./practices.actions";
import { UsersState } from "../../users/store/users.reducers";
import { CompaniesState } from "../../companies/store/companies.reducers";
import { InvoicesState } from "../../invoices/store/invoices.reducers";
import moment  from "moment";
import { PromosState } from "../../promos/store/promos.reducers";
export interface PracticesState {
    orgLoader: boolean;
    orgData: any;
    promocode: any;
    orgCount: number
  };
  
  const initialState: PracticesState = {
    orgLoader: false,
    orgData: [],
    promocode: [],
    orgCount: 0
  };

const _practiceReducer=createReducer(initialState,
    on(LoadPracticesAction,(state,action)=>{
        //const user = {"username":action.username,"password":action.password};
        return{
            ...state,
            orgLoader: true
        }
    }),
    on(LoadPracticesSuccessAction,(state,action)=>{
        const data = action.payload;
        return{
            ...state,
            orgLoader: false,
            orgData: data,
            orgCount: data.length
        }
    }),
    on(ExtendTrialActionSuccess,(state,action: any)=>{
        const data = action.payload;
        var updatedArray = state.orgData.map(function(obj: any) {
            if (obj._id === data.orgId) {
              return { ...obj, end_date: data.extend_date }; // Create a new object with updated age
            }
            return obj; // Return the original object if no update is needed
          });
        return{
            ...state,
            orgLoader: false,
            orgData: updatedArray
        }
    }),
    on(UpdateUrlActionSuccess,(state,action: any)=>{
        const data = action.payload;
        var updatedArray = state.orgData.map(function(obj: any) {
            if (obj._id === data.orgId) {
              return { ...obj, url: data.url }; // Create a new object with updated age
            }
            return obj; // Return the original object if no update is needed
          });
        return{
            ...state,
            orgLoader: false,
            orgData: updatedArray
        }
    }),
    on(UpdatePromoActionSuccess,(state,action: any)=>{
        const data = action.payload;
        var updatedArray = state.orgData.map(function(obj: any) {
            if (obj._id === data.orgId) {
              return { ...obj, promoCode: data.promoCode }; // Create a new object with updated age
            }
            return obj; // Return the original object if no update is needed
          });
        return{
            ...state,
            orgLoader: false,
            orgData: updatedArray
        }
    }),
    on(UpdateStatusActionSuccess,(state,action: any)=>{
        const data = action.payload;
        var updatedArray = state.orgData.map(function(obj: any) {
            if (obj._id === data.orgId) {
                if(data.isCancelled)
                return { ...obj, isCancelled: data.isCancelled, end_date: data.end_date, cancelledDate: data.cancelledDate }; // Create a new object with updated age
                else
                return { ...obj, isCancelled: data.isCancelled, end_date: data.end_date }; // Create a new object with updated age
            }
            return obj; // Return the original object if no update is needed
          });
        return{
            ...state,
            orgLoader: false,
            orgData: updatedArray
        }
    })
)

export const selectPractices = (state: PracticesState) => state;
export const getPractices = (state: PracticesState) => state.orgData;



export const getAllPractices = createSelector(
    selectPractices,
    (data: any) => {
        if(data){
            //console.log(practices);
            return data.practices.orgData;
        }
    }
);

export const getPracticeById = (orgId: string) => createSelector(
    selectCombinedPracticeUser,
    (data: any) => {
        if(data){
            return data.filter((item: any) => {
                if(item._id === orgId){
                    return item
                }
                    
            })
        }
    }
)

const selectUsersState = createFeatureSelector<UsersState>('users');
const selectCompaniesState = createFeatureSelector<CompaniesState>('companies');
const selectInvoicesState = createFeatureSelector<InvoicesState>('invoices');
const selectPromosState = createFeatureSelector<PromosState>('promos');
export const selectedUsers = createSelector(
  selectUsersState,
  (state: UsersState) => state.userData
);

export const selectedCompanies = createSelector(
    selectCompaniesState,
    (state: CompaniesState) => state.compData
  );

  export const selectedDeletedCompanies = createSelector(
    selectCompaniesState,
    (state: CompaniesState) => state.delCompData
  );
  export const selectedInvoices = createSelector(
    selectInvoicesState,
    (state: InvoicesState) => state.invData
  );
  export const selectedPromos = createSelector(
    selectPromosState,
    (state: PromosState) => state.promoData
  );

export const selectCombinedPracticeUser = createSelector(
    getAllPractices,
    selectedUsers,
    selectedCompanies,
    selectedInvoices,
    selectedPromos,
    selectedDeletedCompanies,
    (practices: any,users: any, comp: any, inv: any,promo: any, delcomp: any) => {
      console.log("Practice Store");
      // Combine users and practices data here
      return practices.map((practice: any, index: any) => {
      
      const user = users.find((user: any) => user._id == practice.mainUserId);
      const companies = comp.filter((company: any) => company.organizationId == practice._id && (company.type == "QBO" || company.type == "Xero")).map((comp: any)=>{
            return {
                "_id":comp._id,
                "date_added": moment(comp.date_added).format(),
                "type":comp.type,
                "lastVisited":comp.lastVisited
            }
        });
      const delCompanies = delcomp.filter((company: any) => company.organizationId == practice._id).map((comp: any)=>{
          return {
              "_id":comp._id,
              "date_added": moment(comp.date_added).format(),
              "date_deleted": moment(comp.date_deleted).format(),
              "type":comp.type
          }
      });

      const pracUsers = users.filter((user: any) => {
        if(practice.users.includes(user._id)){
          return user
        }
      });

      const pracPromo = promo.filter((pro: any) => pro.promoCode == practice.promoCode)
      const invoices = inv.filter((invoice: any) => invoice.organizationId == practice._id).map((invoice: any)=>{
        
          return {
              "invStatus":invoice.status,
              "invAmount": invoice.billed,
              "invClients": invoice.noOfSubcriptions,
              "invDate": moment(invoice.billDate).format("MM/DD/YYYY"),
              "exchangeRate": invoice.exchangeRate ? invoice.exchangeRate : 1,
              "unitPrice": invoice.unitPrice?.unitPrice,
              "promoCode": invoice.promoCode ? invoice.promoCode.promoCode : ''
          }
      });
      const promoTotal = invoices.filter((invs: any) => invs.promoCode == practice.promoCode).length;
      
      let promoCode = practice.promoCode;
      if(pracPromo && promoTotal >= pracPromo[0]?.months)
      {
        promoCode = '';
      }
        const mainUsername = user ? (user.firstname + ' ' + user.lastname) : '';
        const mainUseremail = user ? user.email : '';
        let basePrice = practice.currency == "USD" ? practice.plan[0]?.priceUSD : practice.plan[0]?.priceCAD;
        
        let pricing = basePrice;
        let promoType = "";
        let mrr = companies.length * pricing;
        if(promoCode && promoCode != ""){
          promoType = pracPromo[0]?.promoType;
          if(pracPromo && pracPromo[0]?.promoType == "Fixed"){
                pricing = pracPromo[0].promoPrice;
                mrr = pracPromo[0].promoPrice
          }else{
            
            if(pracPromo[0].promoType == "Discount"){
              let newBasePrice = (basePrice * pracPromo[0].promoPrice) / 100;
              pricing = basePrice - newBasePrice;
            }
            if(pracPromo[0].promoType == "Flat"){
              pricing = pracPromo[0].promoPrice;
            }
            mrr = companies.length * pricing;
          }
          
        }
        let date_added = moment(practice.date_added).format("MM/DD/YYYY");
        let companiesCount = companies.length ? companies.length : 0;
        let usersCount = users.length ? users.length : 0;
        let id = index + 1 ;
        // if(params.data.promoType == "Fixed"){
        //   return params.data.pricing;;
        // }
        // return params.data.companies.length * params.data.pricing
        return {
          ...practice,
          promoCode,
          mainUsername,
          mainUseremail,
          companies,
          invoices,
          pracPromo,
          delCompanies,
          pracUsers,
          pricing,
          promoType,
          mrr,
          date_added,
          companiesCount,
          usersCount,
          id
        };
      });
    }
  );

export function practiceReducer(state: any,action: any){
    return _practiceReducer(state,action)
}
