import { createAction, props } from '@ngrx/store';

export const LoadCompaniesAction = createAction(
  'LOAD_COMPANIES'
);

export const LoadCompaniesSuccessAction = createAction(
  'LOAD_COMPANIES_SUCCESS',
  props<{ payload: []}>()
);

export const LoadCompaniesFailAction = createAction(
  'LOAD_COMPANIES_FAIL',
  props<{payload:{}}>()
);

export const LoadDeletedCompaniesAction = createAction(
  'LOAD_DELETED_COMPANIES'
);

export const LoadDeletedCompaniesSuccessAction = createAction(
  'LOAD_DELETED_COMPANIES_SUCCESS',
  props<{ payload: []}>()
);

export const LoadDeletedCompaniesFailAction = createAction(
  'LOAD_DELETED_COMPANIES_FAIL',
  props<{payload:{}}>()
);

export const SyncTransAction = createAction(
  'SYNC_TRANS',
  props<{payload:{}}>()
);
export const UpdateSyncDateAction = createAction(
  'UPDATE_SYNC_DATE',
  props<{payload:{}}>()
);
export const UpdateSyncDateActionSuccess = createAction(
  'UPDATE_SYNC_DATE_SUCCESS',
  props<{payload:{}}>()
);