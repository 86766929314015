import { createReducer, on } from "@ngrx/store"
import { LoginAction, LoginFailAction, LoginSuccessAction, LogoutAction } from "./login.actions";
export interface LoginState {
    userInfo: object;
    isAdmin: object
  };
  
  const initialState: LoginState = {
    userInfo: {},
    isAdmin :{}
  };

const _loginReducer=createReducer(initialState,
    on(LoginAction,(state,action)=>{
        const user = {"username":action.username,"password":action.password};
        return{
            ...state,
            userInfo: user
        }
    }),
    on(LoginSuccessAction,(state,action)=>{
        const user = action.payload;
        return{
            ...state,
            userInfo: user
        }
    }),
    on(LogoutAction,(state,action)=>{
        return{
            ...state,
            userInfo: {},
            isAdmin: {}
        }
    })
)

export function loginReducer(state: any,action: any){
    return _loginReducer(state,action)
}