import { ChangeDetectionStrategy, Component, HostListener, computed, inject, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogoutAction } from '../../auth/login/store/login.actions';
import { RouteConst } from '../../shared/routes.constants';
import { ToggleService } from './toggle.service';
import { CustomizerSettingsService } from '../../components/customizer-settings/customizer-settings.service';
import { FormControl } from '@angular/forms';
//import { getAllPractices, selectedUsers } from '../practices/store/practices.reducers';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonStore } from '../../shared/store/common.store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  public practiceStore = inject(Store);
  commonStore = inject(CommonStore);
  isSticky: boolean = false;
  stateCtrl = new FormControl('');
  isToggled = false;
  @HostListener('window:scroll', ['$event'])
  //public getAllPractices = this.commonStore.dashboardDetails();
  //public selectedUsers = this.practiceStore.selectSignal(selectedUsers);
  searchSignal = signal<string>('');
  filteredOrgs = computed(() => {
      const searchTerm = this.searchSignal().toLowerCase();
      const getAllPractices = this.commonStore.dashboardDetails();
      return getAllPractices
      .filter((practice: any) => practice.organizationname.toLowerCase().includes(searchTerm))
      .map((practice: any) => {
        return {
          ...practice,
        };
      })
  })
  titleService = inject(Title);
  userName = signal<any>(localStorage.getItem('username'));

  checkScroll() {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (scrollPosition >= 50) {
          this.isSticky = false;
      } else {
          this.isSticky = false;
      }
  }

  constructor(
    private loginStore: Store<{login:{userInfo:{},isAdmin:{}}}>,
    private router: Router,
    private toggleService: ToggleService,
    public themeService: CustomizerSettingsService

  ){
    this.toggleService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
   });
   this.router.events.pipe(takeUntilDestroyed()).subscribe((e: any) => {
    if (e instanceof NavigationEnd) {
      this.titleService.setTitle(this.setName(e.url));
    }
  });
  }

ngOnInit(){
  

  this.stateCtrl.valueChanges.pipe(
    debounceTime(300),
    distinctUntilChanged()
  ).subscribe(value => {
    this.searchSignal.set(value || ''); // Ensure value is a string
  });
}
navigate(id:any){
  this.stateCtrl.setValue(null);
  this.router.navigate(['practices',id]);
}
logout(){
  localStorage.clear();
  this.loginStore.dispatch(LogoutAction());
  this.router.navigate([RouteConst.login]);
}

toggleTheme() {
  this.themeService.toggleTheme();
}

toggle() {
  this.toggleService.toggle();
}

toggleSidebarTheme() {
  this.themeService.toggleSidebarTheme();
}

toggleHideSidebarTheme() {
  this.themeService.toggleHideSidebarTheme();
}

toggleCardBorderTheme() {
  this.themeService.toggleCardBorderTheme();
}

toggleHeaderTheme() {
  this.themeService.toggleHeaderTheme();
}

toggleCardBorderRadiusTheme() {
  this.themeService.toggleCardBorderRadiusTheme();
}

toggleRTLEnabledTheme() {
  this.themeService.toggleRTLEnabledTheme();
}

setName(id: any): string {
  const nameMap: { [key: string]: string } = {
    "/dashboard": 'Dashboard',
    "/practices": 'Practices',
    "/users": 'User',
    "/companies": 'Clients',
    "/deleted-companies": 'Delete Clients',
    "/invoices": 'Invoices',
    "/promos": 'Promos',
  };
  return nameMap[id] || 'Xenett Admin';
}

currentDate: Date = new Date();
//formattedDate: any = this.datePipe.transform(this.currentDate, 'dd MMMM yyyy');
}
