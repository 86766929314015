import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RouteConst } from './shared/routes.constants';
import { ToggleService } from './pages/header/toggle.service';
import { CustomizerSettingsService } from './components/customizer-settings/customizer-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Xenett';
  userLoggedIn = false;
  isToggled = false;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private toggleService: ToggleService,
    public themeService: CustomizerSettingsService
  ){
    this.toggleService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
  });
    router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          var path = window.location.pathname;
          var pathsplit = path.split('/');
          var split2 = pathsplit[1].split('?');
          var currentUser = localStorage.getItem('currentUser');


          if (currentUser != null && ( pathsplit[1] === "" || pathsplit[1] === "login" || split2[0] === "login")) {
            this.userLoggedIn = true;
            this.router.navigate(['dashboard']);
          } else {
            if(!currentUser)
              this.userLoggedIn = false;
            else
              this.userLoggedIn = true;
            //this.router.navigate([path]);
          }
        }
      });
  }
  toggleRightSidebarTheme() {
    this.themeService.toggleRightSidebarTheme();
}

toggleHideSidebarTheme() {
    this.themeService.toggleHideSidebarTheme();
}

toggleCardBorderTheme() {
    this.themeService.toggleCardBorderTheme();
}

toggleTheme() {
    this.themeService.toggleTheme();
}

toggleCardBorderRadiusTheme() {
    this.themeService.toggleCardBorderRadiusTheme();
}

toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
}
}
