<header
    class="header-area bg-white border-bottom-radius box-shadow mb-15"
    [ngClass]="{'active': isToggled, 'sticky': isSticky}"
    [class.component-dark-theme]="themeService.isDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
    [class.dark-header]="themeService.isHeaderDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-md-flex align-items-center justify-content-space-between">
        <div class="header-left-side d-flex align-items-center">
            <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
            <form class="search-box position-relative">
                <!-- <input type="text" class="input-search d-block" placeholder="Search here.."> -->
                
                <input matInput
                class="input-search d-block" placeholder="Search Practice here.."
                aria-label="State"
                [matAutocomplete]="auto"
                [formControl]="stateCtrl"
            >

            <mat-autocomplete  #auto="matAutocomplete">
                @for (prac of filteredOrgs(); track prac._id) {
                <mat-option>
                    <a (click)="navigate(prac._id)"><span>{{prac.organizationname}}</span> |
                        <small>Email: {{prac.mainUseremail}}</small></a>
                    
                </mat-option>
               }
            </mat-autocomplete>
            <button type="submit"><i class="ri-search-line"></i></button>

            </form>
            
            
        </div>
        <ul class="header-right-side d-flex align-items-center mt-0 mb-0">
            <li>
                <button mat-icon-button [matMenuTriggerFor]="profileMenu" class="profile-btn main-color border-none bg-transparent position-relative">
                    <img src="https://xenett-cdn.s3.amazonaws.com/production/usersImages/1681790068868_Candid%202.jpg" class="rounded-circle" alt="admin">

                    Admin
                    <span class="fw-semibold">{{userName()}}</span>

                </button>
                <mat-menu class="profile-menu-dropdown" #profileMenu="matMenu" xPosition="before">
                    <ul class="m-0">
                        <li>
                            <a (click)="logout()">
                                <i class="ri-shut-down-line"></i> Logout
                            </a>
                        </li>
                    </ul>
                </mat-menu>
            </li>
        </ul>
    </div>
</header>
