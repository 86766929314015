import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store"
import { LoadUsersAction, LoadUsersSuccessAction, MobileNumberActionSuccess, VerifyUserActionSuccess } from "./users.actions";
import { PracticesState } from "../../practices/store/practices.reducers";

export interface UsersState {
    userLoader: boolean;
    userData: any;
    userCount: number;
};

const initialState: UsersState = {
    userLoader: false,
    userData: [],
    userCount: 0
};

export const usersFeatureKey = 'users';


const _userReducer=createReducer(initialState,
    on(LoadUsersAction,(state,action)=>{
        //const user = {"username":action.username,"password":action.password};
        return{
            ...state,
            userLoader: true
        }
    }),
    on(LoadUsersSuccessAction,(state,action)=>{
        const data = action.payload;
        return{
            ...state,
            userLoader: false,
            userData: data,
            userCount: data.length
        }
    }),
    on(VerifyUserActionSuccess,(state,action: any)=>{
      const data = action.payload;
      var updatedArray = state.userData.map(function(obj: any) {
        if (obj._id === data.userId) {
          return { ...obj, isVerified: data.isVerified }; // Create a new object with updated age
        }
        return obj; // Return the original object if no update is needed
      });
    return{
        ...state,
        userLoader: false,
        userData: updatedArray
    }
  }),
  on(MobileNumberActionSuccess,(state,action: any)=>{
    const data = action.payload;
    var updatedArray = state.userData.map(function(obj: any) {
      if (obj._id === data.userId) {
        return { ...obj, mCountryCode: data.mCountryCode, mobileNo: data.mobileNo }; // Create a new object with updated age
      }
      return obj; // Return the original object if no update is needed
    });
  return{
      ...state,
      userLoader: false,
      userData: updatedArray
  }
})
)
export const getUsersState = createFeatureSelector<UsersState>(usersFeatureKey);
export const getAllUsers = createSelector(getUsersState, (s) =>  {
    return s.userData;
})
export const selectUsers = (state: UsersState) => state;

export const getUsers = (state: UsersState) => state.userData;

// export const getAllUsers = createSelector(
//     selectUsers,
//     (data: any) => {
//         if(data){
//             return data.users.userData;
//         }     
//     }
// );
const selectPracticesState = createFeatureSelector<PracticesState>('practices');

export const selectedPractices = createSelector(
  selectPracticesState,
  (state: PracticesState) => state.orgData
);
export const selectCombinedUserPractice = createSelector(
  getAllUsers,
  selectedPractices,
  (users: any, practices: any) => {
    // Combine users and practices data here
    return users.map((user: any) => {
      const organization = practices.find((org: any) => org.users.includes(user._id));
      const organizationname = organization ? organization.organizationname : '';
    //   const userRole = organization ? organization.mainUserId == user._id ? 'Super Admin' :  : 'Staff';
                    let mainUserId = organization ? organization?.mainuser?._id: '';
                    let orgAdmins = organization && organization.admins ? organization.admins : [];
                    let userRole = "";
                    userRole = user._id == mainUserId ? "Super Admin" : orgAdmins.includes(user._id) ? "Admin" : "Staff";
      return {
        ...user,
        organizationname,
        userRole
      };
    });
  }
);

export function userReducer(state: any,action: any){
    return _userReducer(state,action)
}
