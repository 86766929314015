
<div
    class="sidebar-area bg-white box-shadow"
    [ngClass]="{'active': isToggled()}"
    [class.dark-sidebar]="themeService.isSidebarDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
>
    <div class="logo bg-white">
        <a routerLink="/" class="d-flex align-items-center">
            <img src="assets/img/logo-icon-bkk.png" alt="logo" style="width:38px; height:38px">
            <span>Xenett</span>
        </a>
    </div>
    <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled()}">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
    </div>
    <ng-scrollbar visibility="hover" style="height: 100vh;">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <mat-accordion>
                    <span class="sub-title gray-color">MAIN</span>
                     
                    @for (link of sideBarNavItems(); track link.id) {
                    <a mat-list-item [routerLink]="link.path"  class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i [ngClass]="link.icon"></i>
                        <span class="title">{{ link.title }} </span>
                        @if(!isToggled()){
                            <span class="badge" [ngClass]="link.countClass" *ngIf="link.count">{{ link.count ? '(' + link.count + ')' : ''}}</span>
                        }
                    </a>
                    } 
                    
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>
