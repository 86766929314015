import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store"
import { LoadCompaniesAction, LoadCompaniesSuccessAction, LoadDeletedCompaniesAction, LoadDeletedCompaniesSuccessAction, UpdateSyncDateActionSuccess } from "./companies.actions";
import { UsersState } from "../../users/store/users.reducers";
import { PracticesState } from "../../practices/store/practices.reducers";
export interface CompaniesState {
    compLoader: boolean;
    compData: any;
    compCount: number;
    delCompData: any;
    delCompCount: number;
  };
  
  const initialState: CompaniesState = {
    compLoader: false,
    compData: [],
    compCount: 0,
    delCompData: [],
    delCompCount: 0
  };
  export const companiesFeatureKey = 'companies';

const _companyReducer=createReducer(initialState,
    on(LoadCompaniesAction,(state,action)=>{
        //const user = {"username":action.username,"password":action.password};
        return{
            ...state,
            compLoader: true
        }
    }),
    on(LoadCompaniesSuccessAction,(state,action)=>{
        const data = action.payload;
        return{
            ...state,
            compLoader: false,
            compData: data,
            compCount: data.length
        }
    }),
    on(LoadDeletedCompaniesAction,(state,action)=>{
        //const user = {"username":action.username,"password":action.password};
        return{
            ...state,
            compLoader: true
        }
    }),
    on(LoadDeletedCompaniesSuccessAction,(state,action)=>{
        const data = action.payload;
        return{
            ...state,
            compLoader: false,
            delCompData: data,
            delCompCount: data.length
        }
    }),
    on(UpdateSyncDateActionSuccess,(state,action: any)=>{
        const data = action.payload;
        var updatedArray = state.compData.map(function(obj: any) {
            if (obj._id === data.companyId) {
                
                return { ...obj, startSyncDate: data.startSyncDate }; // Create a new object with updated age
            }
            return obj; // Return the original object if no update is needed
          });
          
        return{
            ...state,
            compLoader: false,
            compData: updatedArray
        }
    })
)

export const getInvoicesState = createFeatureSelector<CompaniesState>(companiesFeatureKey);
export const getAllDelCompanies = createSelector(getInvoicesState, (s) =>  {
    return s.delCompData;
})
export const getAllCompanies = createSelector(getInvoicesState, (s) =>  {
    return s.compData;
})

// export const selectCompanies = (state: CompaniesState) => state;

// export const getAllCompanies = createSelector(
//     selectCompanies,
//     (data: any) => {
//         if(data){
//             console.log('data' , data);
//             return data.companies.compData;
//         }     
//     }
// );

// export const getAllDelCompanies = createSelector(
//     selectCompanies,
//     (data: any) => {
//         if(data){
//             return data.companies.delCompData;
//         }     
//     }
// );


const selectUsersState = createFeatureSelector<UsersState>('users');
const selectPracticesState = createFeatureSelector<PracticesState>('practices');

export const selectedUsers = createSelector(
  selectUsersState,
  (state: UsersState) => state.userData
);

export const selectedPractices = createSelector(
    selectPracticesState,
    (state: PracticesState) => state.orgData
);

export const selectCombinedCompanies = createSelector(
    getAllCompanies,
    selectedUsers,
    selectedPractices,
    (companies: any,users: any, prac: any) => {
      // Combine users and practices data here
      return companies.map((company: any) => {
        let addedById = company.addedBy ? company.addedBy : company.userId;
        const user = users.find((user: any) => user._id == addedById);
      const practice = prac.filter((practice: any) => company.organizationId == practice._id).map((practice: any)=>{
            return {
                "organizationname":practice.organizationname,
                "isBilling": practice.isBilling,
                "isCancelled": practice.isCancelled,
                "end_date": practice.end_date
            }
        });
        const mainUsername = user ? (user.firstname + ' ' + user.lastname) : '';
        const mainUseremail = user ? user.email : '';
        return {
          ...company,
          mainUsername,
          mainUseremail,
          practice,
        };
      });
    }
);

export const selectCombinedDelCompanies = createSelector(
    getAllDelCompanies,
    selectedUsers,
    selectedPractices,
    (companies: any,users: any, prac: any) => {

      // Combine users and practices data here
      return companies.map((company: any) => {
        let addedById = company.deletedBy ? company.deletedBy : company.userId;
      const user = users.find((user: any) => user._id == addedById);
      const practice = prac.filter((practice: any) => company.organizationId == practice._id).map((practice: any)=>{
            return {
                "organizationname":practice.organizationname,
                "isBilling": practice.isBilling,
                "isCancelled": practice.isCancelled,
                "end_date": practice.end_date
            }
        });
        const mainUsername = user ? (user.firstname + ' ' + user.lastname) : '';
        const mainUseremail = user ? user.email : '';
        return {
          ...company,
          mainUsername,
          mainUseremail,
          practice,
        };
      });
    }
);



export function companyReducer(state: any,action: any){
    return _companyReducer(state,action)
}
