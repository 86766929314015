import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { practiceReducer } from '../practices/store/practices.reducers';
import { companyReducer } from '../companies/store/companies.reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PracticesEffects } from '../practices/store/practices.effects';
import {MatListModule} from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { CompaniesEffects } from '../companies/store/companies.effects';
import { userReducer } from '../users/store/users.reducers';
import { UsersEffects } from '../users/store/users.effects';
import { promoReducer } from '../promos/store/promos.reducers';
import { PromosEffects } from '../promos/store/promos.effects';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { invoiceReducer } from '../invoices/store/invoices.reducers';
import { InvoicesEffects } from '../invoices/store/invoices.effects';
@NgModule({
  declarations: [
    SidebarComponent
  ],
  exports:[
    SidebarComponent
  ],
  imports: [
    CommonModule,
    MatListModule, MatExpansionModule,
    RouterModule,NgScrollbarModule,
    StoreModule.forFeature("practices",practiceReducer),
    StoreModule.forFeature("companies",companyReducer),
    StoreModule.forFeature("users",userReducer),
    StoreModule.forFeature("promos",promoReducer),
    StoreModule.forFeature("invoices",invoiceReducer),
    EffectsModule.forFeature([PracticesEffects,CompaniesEffects,UsersEffects, PromosEffects, InvoicesEffects])
  ]
})
export class SidebarModule { }
