import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'practices',
    loadChildren: () => import('./pages/practices/practices.module').then(m => m.PracticesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'companies',
    loadChildren: () => import('./pages/companies/companies.module').then(m => m.CompaniesModule),
    canActivate: [AuthGuard]
  },{
    path: 'deleted-companies',
    loadChildren: () => import('./pages/deleted-companies/deleted-companies.module').then(m => m.DeletedCompaniesModule),
    canActivate: [AuthGuard]
  },{
    path: 'promos',
    loadChildren: () => import('./pages/promos/promos.module').then(m => m.PromosModule),
    canActivate: [AuthGuard]
  },{
    path: 'invoices',
    loadChildren: () => import('./pages/invoices/invoices.module').then(m => m.InvoicesModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
