import { Injectable } from '@angular/core';
import { Router, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouteConst } from '../shared/routes.constants';
import { Observable } from 'rxjs/internal/Observable';
@Injectable()

export class AuthGuard {

    constructor(private router: Router) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
      ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
        if (!localStorage.getItem('currentUser')) {
          window.alert('Access Denied, Login is Required to Access This Page!');
          this.router.navigate([RouteConst.login]);
        }
        return true;
      }
    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     if (localStorage.getItem('currentUser')) {
    //         // logged in so return true
    //         return true;
    //     }
    //     // not logged in so redirect to login page with the return url
    //     this.router.navigate([RouteConst.login]);
    //     return false;
    // }
}