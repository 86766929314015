import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import {
    LoadUsersAction,
    LoadUsersFailAction,
    LoadUsersSuccessAction,
    MobileNumberAction,
    MobileNumberActionSuccess,
    UpdatePasswordAction,
    VerifyUserAction,
    VerifyUserActionSuccess
  } from './users.actions';
  import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/users.service';
  
  @Injectable()
  export class UsersEffects {
  
    loadUsers$ = createEffect(() =>
      this.actions$.pipe(
        // Filters by Action Creator 'login'
        ofType(LoadUsersAction),
        exhaustMap(() =>
          this.userService.getUsers().pipe(
            map((data: any) => {
                return LoadUsersSuccessAction({payload: data});
            }),
            catchError((error) => of(LoadUsersFailAction(error)))
          )
        )
      )
    );

    updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType(UpdatePasswordAction),
      exhaustMap((action) => 
        this.userService.updatePassword(action).pipe(
          map((data: any) => {
            if(data.success){
              this.sharedService.messageService('Updated Successfully!', 5000, 'center', 'top')
            }else{
              this.sharedService.messageService('Update Failed!', 5000, 'center', 'top')
            }

            //return UpdatePasswordActionSuccess({payload: data});
            return data;
          }),
          catchError((error) => of(LoadUsersFailAction(error)))
        )
      )
    ),
    {dispatch:false}
  );

  verifyUser$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType(VerifyUserAction),
      exhaustMap((action) => 
        this.userService.verifyUser(action).pipe(
          map((data: any) => {
            if(data.success){
              this.sharedService.messageService('Updated Successfully!', 5000, 'center', 'top')
            }else{
              this.sharedService.messageService('Update Failed!', 5000, 'center', 'top')
            }

            return VerifyUserActionSuccess({payload: data});
            //return data;
          }),
          catchError((error) => of(LoadUsersFailAction(error)))
        )
      )
    )
  );

  mobileNumber$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType(MobileNumberAction),
      exhaustMap((action) => 
        this.userService.updateMobile(action).pipe(
          map((data: any) => {
            if(data.success){
              this.sharedService.messageService('Updated Successfully!', 5000, 'center', 'top')
            }else{
              this.sharedService.messageService('Update Failed!', 5000, 'center', 'top')
            }

            return MobileNumberActionSuccess({payload: data});
            //return data;
          }),
          catchError((error) => of(LoadUsersFailAction(error)))
        )
      )
    )
  );

    constructor(
      private actions$: Actions,
      private userService: UserService,
      private sharedService: SharedService
    ) {}
  }
  