<!-- <mat-drawer-container *ngIf="userLoggedIn" class="xt-drawer-container" autosize>
  <mat-drawer class="xt-drawer" #drawer opened mode="side">
    <mat-toolbar class="header">
      <img title="Xenett" src="https://uploads-ssl.webflow.com/62ff26d72ea5e0d106feb3af/6361230551017e327c4ae1ac_Xenett%20Logo%20Only.svg">
    </mat-toolbar>
    <app-sidebar class="contents"></app-sidebar>
  </mat-drawer>

  <mat-drawer-content>
  <mat-toolbar class="main xt-header" *ngIf="userLoggedIn">
    <div class="header-left">
      <button type="button" mat-button title="Toggle Sidebar" (click)="drawer.toggle()">
        <mat-icon fontIcon="menu"></mat-icon>
      </button>
    </div>
    <div class="header-right">
      <app-header></app-header>
    </div>
  </mat-toolbar>
  <router-outlet *ngIf="userLoggedIn"></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
<router-outlet *ngIf="!userLoggedIn"></router-outlet> -->
<div
    [ngClass]="{'blank-page': router.url === '/error-500' || router.url === '/authentication/reset-password' || router.url === '/authentication/forgot-password' || router.url === '/login' || router.url === '/authentication/register' || router.url === '/authentication/signin-signup' || router.url === '/authentication/logout' || router.url === '/authentication/confirm-mail' || router.url === '/authentication/lock-screen' || router.url === '/coming-soon'}"
    [class.dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <app-sidebar *ngIf="userLoggedIn"></app-sidebar>
    <div
        class="main-content d-flex flex-column"
        [ngClass]="{'active': isToggled}"
        [class.right-sidebar]="themeService.isRightSidebar()"
        [class.hide-sidebar]="themeService.isHideSidebar()"
    >
        <app-header *ngIf="userLoggedIn"></app-header>
        <router-outlet></router-outlet>
        <div class="flex-grow-1"></div>
        <!-- <app-footer></app-footer> -->
    </div>
    <app-customizer-settings *ngIf="userLoggedIn"></app-customizer-settings>
</div>