import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import {
  LoadPracticesAction,
  LoadPracticesFailAction,
  LoadPracticesSuccessAction,
  ExtendTrialAction,
  ExtendTrialActionSuccess,
  UpdateUrlAction,
  UpdateUrlActionSuccess,
  UpdatePromoAction,
  UpdatePromoActionSuccess,
  UpdateStatusAction,
  UpdateStatusActionSuccess
} from './practices.actions';
import { PracticeService } from '../../../services/practices.service';
import { SharedService } from '../../../services/shared.service';

@Injectable()
export class PracticesEffects {

  loadPractices$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType(LoadPracticesAction),
      exhaustMap((action) =>
        this.practiceService.getOrgs().pipe(
          map((data: any) => {
            return LoadPracticesSuccessAction({payload: data});
          }),
          catchError((error) => of(LoadPracticesFailAction(error)))
        )
      )
    )
  );

  extendTrial$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType(ExtendTrialAction),
      exhaustMap((action) => 
        this.practiceService.extendTrial(action).pipe(
          map((data: any) => {
            if(data.success){
              this.sharedService.messageService('Updated Successfully!', 5000, 'center', 'top')
            }else{
              this.sharedService.messageService('Update Failed!', 5000, 'center', 'top')
            }

            return ExtendTrialActionSuccess({payload: data});
            //return data;
          }),
          catchError((error) => of(LoadPracticesFailAction(error)))
        )
      )
    )
  );

  updateUrl$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType(UpdateUrlAction),
      exhaustMap((action) => 
        this.practiceService.updateUrl(action).pipe(
          map((data: any) => {
            if(data.success){
              this.sharedService.messageService('Updated Successfully!', 5000, 'center', 'top')
            }else{
              this.sharedService.messageService('Update Failed!', 5000, 'center', 'top')
            }

            return UpdateUrlActionSuccess({payload: data});
            //return data;
          }),
          catchError((error) => of(LoadPracticesFailAction(error)))
        )
      )
    )
  );

  updatePromo$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType(UpdatePromoAction),
      exhaustMap((action) => 
        this.practiceService.updatePromo(action).pipe(
          map((data: any) => {
            if(data.success){
              this.sharedService.messageService('Updated Successfully!', 5000, 'center', 'top')
            }else{
              this.sharedService.messageService('Update Failed!', 5000, 'center', 'top')
            }

            return UpdatePromoActionSuccess({payload: data});
            //return data;
          }),
          catchError((error) => of(LoadPracticesFailAction(error)))
        )
      )
    )
  );

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType(UpdateStatusAction),
      exhaustMap((action) => 
        this.practiceService.updateStatus(action).pipe(
          map((data: any) => {
            if(data.success){
              this.sharedService.messageService('Updated Successfully!', 5000, 'center', 'top')
            }else{
              this.sharedService.messageService('Update Failed!', 5000, 'center', 'top')
            }

            return UpdateStatusActionSuccess({payload: data});
            //return data;
          }),
          catchError((error) => of(LoadPracticesFailAction(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private practiceService: PracticeService,
    private sharedService: SharedService
  ) {}
}
